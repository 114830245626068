import ApiClient from './ApiClient'
import Routes from './routes'

interface Data {
  text: string
}

interface Insert {
  type: 'INSERT'
  data: Data
}

interface Remove {
  type: 'REMOVE'
  data: Data
}

interface Match {
  type: 'MATCH' | 'SEMANTIC_MATCH'
  data: Data
}

interface Style {
  style: string
  styleBuiltIn: string
  font: {
    size?: number
    name?: string
    color?: string
  }
}

type RedlineToken = Insert | Remove | Match

export type Redline = Array<RedlineToken>

type PostRedlineResponse = {
  redline: string
  markup: Redline
}

export interface RedlineWithPlainText {
  updatedText: string
  redline: Redline
}

const routes = new Routes()

export async function getRedline(clauseText: string, contentId: number) {
  const apiClient = new ApiClient()
  const body = JSON.stringify({
    contentId,
    text: clauseText,
  })

  try {
    const { data } = await apiClient.post<PostRedlineResponse>(routes.redlineUrl, body)
    return data
  } catch (e) {
    const error = (e as Error).message ?? 'Unknown error'
    throw Error(error)
  }
}

function serialize(doc: Node) {
  const serializer = new XMLSerializer()
  return serializer.serializeToString(doc)
}

function Builder() {
  const doc = document.implementation.createDocument('', '', null)
  return new NodeWrapper(doc, doc)
}

class NodeWrapper {
  doc: XMLDocument
  node: HTMLElement | XMLDocument

  constructor(doc: XMLDocument, node: HTMLElement | XMLDocument) {
    this.doc = doc
    this.node = node
  }

  _createNode(tag: string, attributes: object = {}) {
    const el = this.doc.createElement(tag)
    for (const [k, v] of Object.entries(attributes)) {
      el.setAttribute(k, v)
    }
    return new NodeWrapper(this.doc, el)
  }

  createChild(tag: string, attributes: object = {}) {
    const el = this._createNode(tag, attributes)
    this.node.appendChild(el.node)
    return new NodeWrapper(this.doc, el.node)
  }

  appendText(text: string) {
    const el = this.doc.createTextNode(text)
    this.node.appendChild(el)
    return this
  }

  toString() {
    return serialize(this.node)
  }
}

const packageAttrs = {
  'xmlns:pkg': 'http://schemas.microsoft.com/office/2006/xmlPackage',
}

const partRelsAttrs = {
  'pkg:name': '/_rels/.rels',
  'pkg:contentType': 'application/vnd.openxmlformats-package.relationships+xml',
  'pkg:padding': '512',
}

const relsAttrs = {
  'xmlns:rels': 'http://schemas.openxmlformats.org/package/2006/relationships',
}

const relAttrs = {
  Id: 'rId1',
  Type: 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument',
  Target: 'word/document.xml',
}

const partDocAttrs = {
  'pkg:name': '/word/document.xml',
  'pkg:contentType':
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml',
}

const documentAttrs = {
  'xmlns:w': 'http://schemas.openxmlformats.org/wordprocessingml/2006/main',
}

const updateAttrs = {
  'w:author': 'Bloomberg Law Contract Solutions',
}

function addInsert(node: NodeWrapper, text: string, style: Style) {
  const ins = node.createChild('w:ins', updateAttrs)
  addRun(ins, text, style)
}

function addRemove(node: NodeWrapper, text: string, style: Style) {
  const del = node.createChild('w:del', updateAttrs)
  addRun(del, text, style, 'w:delText')
}

function addRun(node: NodeWrapper, text: string, style: Style, el = 'w:t') {
  const r = node.createChild('w:r')
  const rpr = r.createChild('w:rPr')
  if (style.style) {
    rpr.createChild('w:rStyle', { 'w:val': style.style })
  }
  if (style.font.size) {
    // OOXML has size in half points so we need to double the value
    rpr.createChild('w:sz', { 'w:val': style.font.size * 2 })
  }
  if (style.font.name) {
    rpr.createChild('w:rFonts', {
      'w:ascii': style.font.name,
      'w:ansi': style.font.name,
      'w:cs': style.font.name,
    })
  }
  if (style.font.color) {
    rpr.createChild('w:color', { 'w:val': style.font.color })
  }
  r.createChild(el, { 'xml:space': 'preserve' }).appendText(text)
}

const actionByType = {
  INSERT: addInsert,
  REMOVE: addRemove,
  MATCH: addRun,
  SEMANTIC_MATCH: addRun,
}

function buildInsertBoilerplate(builder: NodeWrapper) {
  const pkg = builder.createChild('pkg:package', packageAttrs)
  pkg
    .createChild('pkg:part', partRelsAttrs)
    .createChild('pkg:xmlData')
    .createChild('rels:Relationships', relsAttrs)
    .createChild('rels:Relationship', relAttrs)
  return pkg
    .createChild('pkg:part', partDocAttrs)
    .createChild('pkg:xmlData')
    .createChild('w:document', documentAttrs)
    .createChild('w:body')
    .createChild('w:p')
}

function insertDiffElements(paragraph: NodeWrapper, redline: Redline, style: Style) {
  for (const {
    type,
    data: { text },
  } of redline) {
    actionByType[type](paragraph, text, style)
  }
}

export function redlineToOOXML(redline: Redline, style: Style) {
  const builder = Builder()
  const para = buildInsertBoilerplate(builder)
  insertDiffElements(para, redline, style)
  return builder.toString()
}

export function markupToRedline(markup: Redline) {
  let redline = ''
  for (const {
    type,
    data: { text },
  } of markup) {
    redline += `<span class="${type.toLowerCase()}">${text}</span>`
  }
  return redline
}
