import { useContext } from 'react'
import { ActionButton } from '@fluentui/react'

import { MIMETYPE_DOCX } from '@blaw/contracts-api-schema'
import DocumentOpenStatus from '@components/DocumentOpenStatus'
import { StoreContext } from '@contexts/StoreContext'
import useDocumentOpen from '@hooks/useDocumentOpen'
import { formSubmitted } from '@modules/analytics'
import Contract from '@modules/Contract'
import {
  MetadataDetail,
  hasLocationInfo,
  storePageToNavigateTo,
  storeDetailInfoInLocalStorage,
} from '@modules/ContractDetail'
import { selectDetailInDocument } from '@modules/DocumentDefinitions'
import { useDataInjection } from '@hooks/useDataInjection'

interface Props {
  label?: string
  idx?: number
  detail?: MetadataDetail
  contract?: Contract
}

export default function ViewExtractedDataPointBtn(props: Props) {
  const { label = '', idx = 0, detail, contract } = props
  const { loadingDocument, openDocumentError, setOpenDocumentError, openDocumentHandler } =
    useDocumentOpen()
  const { pageTitle } = useContext(StoreContext)
  const { documentId } = useDataInjection()

  return (
    <>
      <ActionButton
        disabled={!!loadingDocument}
        iconProps={{ iconName: 'DocumentSearch' }}
        style={{
          display: detail && hasLocationInfo(detail) ? 'initial' : 'none',
          height: 'fit-content',
        }}
        onClick={() => handleOnClick(detail?.documentId)}
        title="View in Document"
      />
      <DocumentOpenStatus
        loadingMessage="Opening Document..."
        loadingDocument={loadingDocument}
        openDocumentError={openDocumentError}
        setOpenDocumentError={setOpenDocumentError}
        clearErrorDelay={10000}
      />
    </>
  )

  async function handleOnClick(detailDocId?: string) {
    if (!detailDocId || !contract || !detail) return

    const selected = detailDocId === documentId && (await selectDetailInDocument(detail, idx))
    if (!selected) {
      const mimeType = contract.children.find(child => child.id === detailDocId)?.mimeType || ''
      mimeType === MIMETYPE_DOCX && storeDetailInfoInLocalStorage(detail, idx)
      storePageToNavigateTo('/contracts/detect')

      openDocumentHandler(detailDocId, contract?.id, mimeType, undefined, undefined, true)
    }

    formSubmitted({
      pageTitle,
      itemClicked: 'View in Document (Bookmarking)',
      eventDetails: [label],
    })
  }
}
