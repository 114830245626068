import { DefaultButton, Stack } from '@fluentui/react'
import { useNavigate } from 'react-router'

import { useContractTaskPaneViewed } from '@modules/analytics'
import TopNav from '@components/TopNav'
import StyledStack from '@components/StyledStack'
import { CreationType } from '@modules/Playbook'
import { useTranslation } from '@hooks/useTranslation'

export default function PlaybookBuilderCreationType() {
  const navigate = useNavigate()
  const nextPage = '/playbooks/builder/2'
  const { t } = useTranslation()

  const pageTitle = t('page.Playbook Builder.Title')

  useContractTaskPaneViewed({ pageTitle })

  return (
    <>
      <TopNav title={pageTitle} prevPath="#/playbooks" showAIBadge />
      <StyledStack>
        <Stack.Item style={{ marginTop: '0.5em' }}>
          Start with a blank playbook and enter your clause language and compliance instructions
          manually.
        </Stack.Item>
        <Stack.Item style={{ marginTop: '1em' }}>
          <DefaultButton
            onClick={() => navigate(`${nextPage}?creationType=${CreationType.MANUAL}`)}
          >
            Create From Scratch
          </DefaultButton>
        </Stack.Item>
        <Stack.Item style={{ marginTop: '2em' }}>
          Pre-populate your playbook with clause language and guidance extracted from a template
          stored on Bloomberg Law Contract Solutions.
        </Stack.Item>
        <Stack.Item style={{ marginTop: '1em' }}>
          <DefaultButton onClick={() => navigate(`${nextPage}?creationType=${CreationType.AI}`)}>
            Create From Template
          </DefaultButton>
        </Stack.Item>
      </StyledStack>
    </>
  )
}
