import { useContext, useEffect } from 'react'
import { Stack } from '@fluentui/react'
import { t } from 'i18next'

import { useContractTaskPaneViewed } from '@modules/analytics'
import TopNav from '@components/TopNav'
import StyledStack from '@components/StyledStack'
import { PlaybooksContext } from '@contexts/PlaybooksContext'
import QuickMessage from '@components/QuickMessage'
import PlaybooksList from '@components/PlaybooksList'
import { StoreContext } from '@contexts/StoreContext'
import FilterButton from '@components/FilterButton/FilterButton'
import { useNavigate } from 'react-router-dom'
import { StyledDivider } from '@baseComponents/StyledDivider'

const playbooksLabel = t('page.Playbooks.plural')
const pageTitle = `My ${playbooksLabel}`

export default function Playbooks() {
  const {
    query,
    error,
    setError,
    warn,
    Pagination,
    topOfPageRef,
    loading,
    playbooks,
    loadPage,
    numFilters,
    items,
  } = useContext(PlaybooksContext)
  const { access } = useContext(StoreContext)
  const navigate = useNavigate()

  useEffect(() => {
    setError('')
  }, [playbooks.length])

  useEffect(() => {
    if (location.pathname.startsWith('/playbooks')) {
      loadPage(query)
      if (location.pathname === '/playbooks/filters') navigate('/playbooks')
    }
  })

  useContractTaskPaneViewed({ pageTitle })

  return (
    <div ref={topOfPageRef}>
      <TopNav
        title={pageTitle}
        showPrimaryButton={access.canCreatePlaybook()}
        primaryButtonLabel="Add"
        primaryButtonTooltip="Create Playbook"
        primaryButtonHref="#/playbooks/builder/1"
        showAIGeneratedBadge
      />
      <StyledStack style={{ paddingTop: 0 }}>
        <Stack.Item>
          <FilterButton
            isLinkDisabled={
              loading || !items.count || !!(items.facets && items.facets.length === 0)
            }
            pathName="/playbooks/filters"
            numFilters={numFilters}
          />
          <StyledDivider />
          {renderPlaybooks()}
        </Stack.Item>
      </StyledStack>
    </div>
  )

  function renderPlaybooks() {
    if (error) return <QuickMessage msg={error} type="error" />
    if (warn) return <QuickMessage msg={warn} type="warning" />
    if (!playbooks.length && !loading)
      return <QuickMessage msg={`0 ${playbooksLabel} found`} type="warning" />

    return (
      <Pagination>
        <PlaybooksList resources={playbooks} />
      </Pagination>
    )
  }
}
