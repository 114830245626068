import { AxiosError, AxiosResponse } from 'axios'
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react'

import {
  type AccessControls,
  type ContractMetadata,
  type Resource,
  isPrivate,
  makePrivate,
  makePublic,
} from '@blaw/contracts-api-schema'
import { StoreContext } from '@contexts/StoreContext'
import ApiClient from '@modules/ApiClient'
import Contract, { ContractFormFields, ContractResourceWithDetails } from '@modules/Contract'
import { KeyTerm } from '@modules/KeyTerm'
import useContextualContractId from '@hooks/useContextualContractId'
import { FacetListItem, ItemAndCount } from '@modules/ClauseAnalyzer'

type Props = PropsWithChildren

interface ContractContextState {
  loadContract: () => Promise<void>
  updateAccess: (
    accessControls: AccessControls,
    resourceId: string,
    envelopeIds: string[],
  ) => Promise<void>
  updateAccessV2: (
    accessControls: AccessControls,
    resourceId: string,
    envelopeIds: string[],
  ) => Promise<void>
  updateStatus: (selectedStatusIdx: string) => Promise<void>
  updateMetadata: (
    metadata: ContractMetadata | string | ContractFormFields,
    e: React.FormEvent<HTMLFormElement>,
  ) => Promise<AxiosResponse<any>>
  updateKeyTerms: (
    metadata: ContractMetadata | ContractFormFields,
    e: React.FormEvent<HTMLFormElement>,
  ) => Promise<AxiosResponse<any>>
  deleteKeyTerm: (term: KeyTerm, e: React.FormEvent<HTMLFormElement>) => Promise<AxiosResponse<any>>
  contractId?: string
  contract: Contract | undefined
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  title: string
  error?: string
  updating: boolean
  children: Resource[]
  statusFormHidden: boolean
  setStatusFormHidden: React.Dispatch<React.SetStateAction<boolean>>
  accessFormHidden: boolean
  setAccessFormHidden: React.Dispatch<React.SetStateAction<boolean>>
  updateError: string | null
  setUpdateError: React.Dispatch<React.SetStateAction<string | null>>
  isKeyTermsStatusHidden: boolean
  errorCode: number | undefined
  getContractTypeFacet: (facets: FacetListItem[]) => ItemAndCount | undefined
}

export const ContractContext = createContext({} as ContractContextState)

type ContractData = { contract: ContractResourceWithDetails }

const loadingText = 'Loading...'

const ContractContextProvider: FC<Props> = (props: Props) => {
  const contractId = useContextualContractId()
  const {
    routes,
    storeSessionInfo,
    loggedIn,
    navigateToExtractedObligationEnabled,
    metadataConfig,
    loadingMetadataConfig,
  } = useContext(StoreContext)
  const [error, setError] = useState<string>()
  const [errorCode, setErrorCode] = useState<number | undefined>()
  const [loading, setLoading] = useState(Boolean(contractId))
  const [title, setTitle] = useState(loadingText)
  const [isKeyTermsStatusHidden, setIsKeyTermsStatusHidden] = useState(false)
  const [contract, setContract] = useState<Contract>()
  const [children, setChildren] = useState<Resource[]>([])
  const [updateError, setUpdateError] = useState<string | null>(null)
  const [updating, setUpdating] = useState(false)
  const [statusFormHidden, setStatusFormHidden] = useState(true)
  const [accessFormHidden, setAccessFormHidden] = useState(true)
  const apiClient = new ApiClient(storeSessionInfo, setError)

  useEffect(() => {
    if (
      loggedIn &&
      contractId &&
      !loadingMetadataConfig &&
      navigateToExtractedObligationEnabled !== undefined
    )
      loadContract()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId, loadingMetadataConfig, navigateToExtractedObligationEnabled])

  async function loadContract() {
    setError(undefined)
    setErrorCode(undefined)
    setLoading(true)
    setTitle(loadingText)
    const url = `${routes.contractUrl}/${contractId}${
      navigateToExtractedObligationEnabled ? '?getRunInfo=true' : ''
    }`

    try {
      const {
        data: { contract },
      } = await apiClient.get<ContractData>(url)
      const c = new Contract(contract)

      setIsKeyTermsStatusHidden(
        c.isFinalized() || (metadataConfig && c.numExistingKeyTerms(metadataConfig.value)) === 0,
      )
      setTitle(c.title)
      setContract(c)
      setChildren(c.children)
    } catch (e) {
      console.error(e)
      setTitle('')
      setError((e as Error).message)
      setErrorCode((e as AxiosError).response?.status)
    }
    setLoading(false)
  }

  async function updateAccess(
    accessControls: AccessControls,
    resourceId: string,
    envelopeIds: string[],
  ) {
    setUpdateError(null)
    setUpdating(true)

    const newAccessControls = isPrivate(accessControls)
      ? makePublic(accessControls)
      : makePrivate(accessControls)

    try {
      const ids = [resourceId, ...(envelopeIds ?? [])]
      for (const id of ids) {
        await apiClient.put(routes.putUpdateAccessUrl(id), newAccessControls)
      }
      await loadContract()
      setAccessFormHidden(true)
    } catch (e) {
      console.error(e)
      setUpdateError((e as Error).message)
    }

    setUpdating(false)
  }

  async function updateAccessV2(
    accessControls: AccessControls,
    resourceId: string,
    envelopeIds: string[],
  ) {
    setUpdateError(null)
    setUpdating(true)

    try {
      const ids = [resourceId, ...(envelopeIds ?? [])]
      for (const id of ids) {
        await apiClient.put(routes.putUpdateAccessUrl(id), accessControls)
      }
      await loadContract()
    } catch (e) {
      console.error(e)
      setUpdateError((e as Error).message)
    }

    setUpdating(false)
  }

  async function updateStatus(selectedStatusIdx: string) {
    if (!contractId)
      throw Error('No Contract ID found. Please reload or ensure this Contract has been saved.')

    setUpdateError(null)
    setUpdating(true)

    try {
      const url = routes.putMetadataKeyUrl(contractId, 'latest', 'contract_status')
      await apiClient.put(url, { status: selectedStatusIdx })
      await loadContract()
      setStatusFormHidden(true)
    } catch (e) {
      console.error(e)
      setUpdateError((e as Error).message)
    }

    setUpdating(false)
  }

  async function updateMetadata(
    metadata: ContractMetadata | string | ContractFormFields,
    e: React.FormEvent<HTMLFormElement>,
  ) {
    e.preventDefault()
    if (!contractId)
      throw Error('No Contract ID found. Please reload or ensure this Contract has been saved.')

    return await apiClient.put(routes.editMetadataUrl(contractId, 'latest'), metadata)
  }

  function formatMetadataBody(metadata: ContractMetadata | ContractFormFields) {
    return {
      resource_ids_versions: [{ resource_id: contractId }],
      metadata,
      resource_type: 'contract',
    }
  }

  async function updateKeyTerms(
    metadata: ContractMetadata | ContractFormFields,
    e: React.FormEvent<HTMLFormElement>,
  ) {
    e.preventDefault()
    return await apiClient.put(routes.bulkMetadataUrl, formatMetadataBody(metadata))
  }

  function formatDeleteBody(term: KeyTerm) {
    return {
      resource_ids_versions: [{ resource_id: contractId }],
      fields_add: {},
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      fields_delete: { [term.key!]: [term.value] },
    }
  }

  async function deleteKeyTerm(term: KeyTerm, e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    return await apiClient.patch(routes.bulkMetadataUrl, formatDeleteBody(term))
  }

  function getContractTypeFacet(facets: FacetListItem[]) {
    if (!contract) return

    return facets
      .find(f => f.field === 'contract_type')
      ?.values.find(({ label }) => label === contract.type)
  }

  const value = {
    loadContract,
    updateAccess,
    updateAccessV2,
    updateStatus,
    updateMetadata,
    updateKeyTerms,
    deleteKeyTerm,
    contractId,
    contract,
    loading,
    setLoading,
    title,
    error,
    updating,
    children,
    statusFormHidden,
    setStatusFormHidden,
    accessFormHidden,
    setAccessFormHidden,
    updateError,
    setUpdateError,
    isKeyTermsStatusHidden,
    errorCode,
    getContractTypeFacet,
  }

  return <ContractContext.Provider value={value}>{props.children}</ContractContext.Provider>
}

export default ContractContextProvider
