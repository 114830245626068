import { Text } from '@fluentui/react-components'

interface Props {
  message: string
}

const HumanMessage = ({ message }: Props) => {
  return (
    <div
      style={{
        backgroundColor: '#f0f0f0',
        padding: '10px 20px',
        borderRadius: '25px',
        color: 'rgb(77, 77, 77)',
        width: 'fit-content',
        maxWidth: '60%',
        alignSelf: 'flex-end',
        margin: '.8em 0',
      }}
    >
      <Text>{message}</Text>
    </div>
  )
}

export default HumanMessage
