import ReactMarkdown from 'react-markdown'
import { makeStyles, Text, tokens } from '@fluentui/react-components'
import rehypeRaw from 'rehype-raw'
import { NeutralColors, SharedColors } from '@fluentui/react'

import { Chunk } from '@modules/Foundry'
import { selectSectionInDocument } from '@modules/wordDocument'

interface Props {
  chunks: Chunk[]
}

interface ReferenceButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  'data-reference'?: string
}

const useStyles = makeStyles({
  badge: {
    border: `1px solid ${tokens.colorBrandBackgroundInvertedPressed}`,
    backgroundColor: tokens.colorBrandBackgroundInvertedHover,
    color: tokens.colorBrandForegroundLink,
    cursor: 'pointer',
    font: 'inherit',
    borderRadius: '30px',
    fontSize: '.7rem',
    margin: '0px 2px',
    padding: '0px 6px',
  },
})

const AIMessage = ({ chunks }: Props) => {
  const styles = useStyles()

  const mergedText = chunks
    .map(chunk => {
      if (chunk.type === 'text') {
        return chunk.value
      } else if (chunk.type === 'reference') {
        return `<button data-reference='${chunk.block_id}'>${chunk.text}</button>`
      } else {
        return ''
      }
    })
    .join('')

  return (
    <Text>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          p: 'span',
          button: (props: ReferenceButtonProps) => (
            <button
              {...props}
              className={styles.badge}
              onClick={event => {
                event.preventDefault()

                const referenceParaIndex = props['data-reference']
                if (referenceParaIndex) {
                  selectSectionInDocument(parseInt(referenceParaIndex))
                } else {
                  throw new Error('paragraph idx not found in the text')
                }
              }}
            />
          ),
        }}
      >
        {mergedText}
      </ReactMarkdown>
    </Text>
  )
}

export default AIMessage
