import { useContext } from 'react'

import FormDialog from '@components/FormDialog'
import { KeyTermsContext } from '@contexts/KeyTermsContext'
import KeyTermFormFooter from '@components/KeyTermFormFooter'
import useKeyTermForm from '@hooks/useKeyTermForm'
import ErrorMessage from '@components/ErrorMessage'
import { ContractContext } from '@contexts/ContractContext'
import { StoreContext } from '@contexts/StoreContext'

const EditKeyTermForm: React.FC = () => {
  const { metadataConfig } = useContext(StoreContext)
  const { formValid, editKeyTermModalActive, currentFormComponent, newKeyTerm, submittingKeyTerm } =
    useContext(KeyTermsContext)
  const { updateKeyTerms, contract } = useContext(ContractContext)
  const { handleFormSubmit, formError } = useKeyTermForm()

  const [FormComponent, keyTermType] = currentFormComponent
  const footerBtnLabel = submittingKeyTerm ? 'Applying...' : 'Apply'
  const footerBtnTitle = submittingKeyTerm ? 'Editing Key Term' : 'Edit Key Term'

  if (
    !(
      editKeyTermModalActive &&
      contract &&
      newKeyTerm &&
      FormComponent &&
      keyTermType &&
      metadataConfig
    )
  )
    return null

  return (
    <FormDialog title="Edit Key Term">
      <form
        onSubmit={e =>
          handleFormSubmit(
            'edit',
            contract.editKeyTerm(newKeyTerm, metadataConfig.value),
            e,
            updateKeyTerms,
          )
        }
      >
        <ErrorMessage message={formError} />
        <FormComponent keyTermType={keyTermType} />
        <KeyTermFormFooter
          formValid={formValid}
          primaryBtnLabel={footerBtnLabel}
          primaryBtnTitle={footerBtnTitle}
          disabled={submittingKeyTerm}
        />
      </form>
    </FormDialog>
  )
}

export default EditKeyTermForm
