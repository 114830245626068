import { StyledDivider } from '@baseComponents/StyledDivider'
import Contract from '@modules/Contract'
import LinkButton from '@components/LinkButton'
import { FontWeights } from '@fluentui/react'

interface Props {
  contract: Contract
  beforeContent?: JSX.Element | JSX.Element[]
  iconName: string
  iconColor: string
  children: JSX.Element | JSX.Element[]
}

export default function ContractsCard(props: Props) {
  const { contract, beforeContent, iconName, iconColor, children } = props

  if (!contract) return null

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1em',
          marginTop: '1em',
          position: 'relative',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {beforeContent}
          <LinkButton
            href={`#/contracts/${contract.id}`}
            iconName={iconName}
            iconColor={iconColor}
            buttonStyles={{
              root: { fontWeight: FontWeights.semibold, justifyContent: 'begin' },
              icon: { lineHeight: '1.1em' },
            }}
          >
            {contract.title}
          </LinkButton>
          {children}
        </div>
      </div>
      <StyledDivider />
    </>
  )
}
