import { getDocParagraphsText } from './wordDocument'
import ApiClient from './ApiClient'
import { Chunk } from './Foundry'
import { getContentHash } from './DocumentDefinitions'
import { CONTRACTS_API_HOST } from '@src/constants'

const FOUNDRY_HOST = `${CONTRACTS_API_HOST}/drafting-api`

export async function initSession(apiClient: ApiClient) {
  const url = `${FOUNDRY_HOST}/sessions`
  const response = await apiClient.post<{ session_id: string }>(url)
  return response.data.session_id
}

export async function createChat(session_id: string, apiClient: ApiClient) {
  const url = `${FOUNDRY_HOST}/sessions/${session_id}/chats`
  const response = await apiClient.post<{ chat_id: string }>(url)
  return response.data.chat_id
}

async function sendMessage(endpoint: string, userMessageContent: Chunk[], apiClient: ApiClient) {
  const paras = await getDocParagraphsText()
  const hash = await getContentHash()
  const response = await apiClient.post<{ actions: any }>(endpoint, {
    document: {
      version: {
        id: hash,
        timestamp: new Date().toISOString(),
      },
      paras,
    },
    message_content: userMessageContent,
    args: {},
  })
  return response.data.actions
}

async function fetchTokens(endpoint: string, apiClient: ApiClient) {
  const response = await apiClient.get<{ chunks: Chunk[]; actions: any }>(endpoint)
  return response.data
}

export async function* pollTokensInBatches(
  sessionId: string,
  chatId: string,
  userMessageContent: Chunk[],
  apiClient: ApiClient,
) {
  const tokenBuffer: any[] = []

  let actions = await sendMessage(
    `${FOUNDRY_HOST}/sessions/${sessionId}/chats/${chatId}`,
    userMessageContent,
    apiClient,
  )
  let response

  while (actions && actions.stream_response) {
    response = await fetchTokens(`${FOUNDRY_HOST}${actions.stream_response.href}`, apiClient)
    actions = response.actions

    if (response.chunks.length > 0) {
      tokenBuffer.push(...response.chunks)
    }
    const batchSize = Math.min(30, tokenBuffer.length)
    const batch = tokenBuffer.splice(0, batchSize)
    yield batch
    await new Promise(resolve => setTimeout(resolve, 200))
  }

  if (tokenBuffer.length > 0) {
    yield tokenBuffer
  }
}
