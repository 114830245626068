import { useContext, useEffect } from 'react'
import { Stack } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'

import TopNav from '@components/TopNav'
import ContractsList from '@components/ContractsList'
import KeywordSearch from '@components/KeywordSearch'
import StyledStack from '@components/StyledStack'
import QuickMessage from '@components/QuickMessage'
import { useContractTaskPaneViewed } from '@modules/analytics'
import { ContractsContext } from '@contexts/ContractsContext'
import { StyledDivider } from '@baseComponents/StyledDivider'
import { StoreContext } from '@contexts/StoreContext'
import ErrorMessage from '@components/ErrorMessage'
import FilterButton from '@components/FilterButton/FilterButton'
import { useTranslation } from '@hooks/useTranslation'

const pageTitle = 'Contracts'

const Contracts: React.FC = () => {
  const {
    query,
    error,
    setError,
    warn,
    isDocCardExpanded,
    setIsDocCardExpanded,
    Pagination,
    pageNum,
    topOfPageRef,
    loading,
    numFilters,
    contracts,
    setContracts,
    users,
    loadingUsers,
    items,
    loadPage,
    search,
  } = useContext(ContractsContext)
  const { access, metadataConfig, loadingMetadataConfig } = useContext(StoreContext)
  const navigate = useNavigate()
  const isViewingDocuments = Boolean(contracts[0]?.parentName.length && !loading && !warn && !error)
  const { t } = useTranslation()
  const label = t('label.contract')

  useEffect(() => {
    setError('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts.length])

  useEffect(() => {
    if (location.pathname.startsWith('/contracts')) {
      loadPage(query)
      if (location.pathname === '/contracts/filters') navigate('/contracts')
    }
  })

  useEffect(() => {
    // Fluent UI's recommended way to re-render List when the 'items' prop doesn't change
    if (isDocCardExpanded) setContracts([...contracts])
    // Note: eslint disable because suggested fix causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDocCardExpanded])

  useContractTaskPaneViewed({
    pageTitle,
    eventDetails: [pageNum.toString()],
  })

  if (!loadingMetadataConfig && metadataConfig?.error) {
    return (
      <div>
        <TopNav title={pageTitle} />
        <ErrorMessage message={metadataConfig.error} />
      </div>
    )
  }

  return (
    <div ref={topOfPageRef}>
      <TopNav
        title={pageTitle}
        showAIBadge
        showPrimaryButton={access.canCreateContract()}
        primaryButtonTooltip="Upload Contract"
        onPrimaryButtonClick={() => navigate('/contracts/new')}
      />
      <StyledStack>
        <Stack.Item>
          <KeywordSearch
            query={query}
            loading={loading}
            search={search}
            placeholder={t('placeholder.contracts-search')}
          />
          <FilterButton
            isLinkDisabled={
              loading || !items.count || !!(items.facets && items.facets.length === 0)
            }
            pathName="/contracts/filters"
            numFilters={numFilters}
          />
          <StyledDivider />
          {renderContractsContent()}
        </Stack.Item>
      </StyledStack>
    </div>
  )

  function renderContractsContent() {
    if (error) return <QuickMessage msg={error} type="error" />
    if (warn) return <QuickMessage msg={warn} type="warning" />
    if (!contracts.length && !loading)
      return <QuickMessage msg={`0 ${label}s found`} type="warning" />

    return (
      <Pagination>
        <ContractsList
          contracts={contracts}
          users={users}
          loadingUsers={loadingUsers}
          loading={loading}
          isDocCardExpanded={isDocCardExpanded}
          setIsDocCardExpanded={setIsDocCardExpanded}
          isViewingDocuments={isViewingDocuments}
        />
      </Pagination>
    )
  }
}

export default Contracts
