import { useContext } from 'react'

import { OXContext } from '@contexts/OXContext'
import { OXFormProps } from '@modules/OXForm'
import StyledTextField from '@components/StyledTextField'
import CopyFromSelectionBtn from '@components/CopyFromSelectionBtn'
import { useTranslation } from '@hooks/useTranslation'
import { disabledTextFieldError } from '@modules/sharedStyles'

export default function TextOXForm({ schemaKey, submitting }: OXFormProps) {
  const { copySelectionDisabled, formData, updateTextOX, updateNotesOX, copyFromSelection } =
    useContext(OXContext)
  const { t } = useTranslation()
  const textOX = formData.entries[schemaKey]
  const disabled = !textOX.included || submitting

  return (
    <>
      <StyledTextField
        required
        label={textOX.label}
        disabled={disabled}
        value={typeof textOX.value === 'string' ? textOX.value : undefined}
        onChange={e => updateTextOX(schemaKey, e.currentTarget.value)}
        errorMessage={textOX.errorMessage}
        styles={disabled ? disabledTextFieldError : {}}
        multiline
      />
      <StyledTextField
        label={t('label.notes')}
        disabled={disabled}
        value={textOX.notes}
        onChange={e => updateNotesOX(schemaKey, e.currentTarget.value)}
        multiline
        styles={{ root: { marginTop: '1em' } }}
      />
      <CopyFromSelectionBtn
        onClick={() => copyFromSelection(schemaKey)}
        disabled={copySelectionDisabled || disabled}
      />
    </>
  )
}
