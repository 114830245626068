import { useContext } from 'react'
import { ComboBox, NeutralColors, SharedColors } from '@fluentui/react'

import { OXContext } from '@contexts/OXContext'
import { OXFormProps } from '@modules/OXForm'
import { comboBoxStyles } from '@modules/sharedStyles'
import StyledTextField from '@components/StyledTextField'
import CopyFromSelectionBtn from '@components/CopyFromSelectionBtn'
import { useTranslation } from '@hooks/useTranslation'

export default function ChoiceTextOXForm({ schemaKey, submitting }: OXFormProps) {
  const { copySelectionDisabled, formData, updateChoiceTextOX, updateNotesOX, copyFromSelection } =
    useContext(OXContext)
  const { t } = useTranslation()
  const choiceTextOX = formData.entries[schemaKey]
  const disabled = !choiceTextOX.included || submitting

  return (
    <>
      <ComboBox
        required
        label={choiceTextOX.label}
        disabled={disabled}
        styles={comboBoxStyles}
        placeholder={t('placeholder.select-one')}
        defaultSelectedKey={typeof choiceTextOX.value === 'string' ? choiceTextOX.value : undefined}
        options={choiceTextOX.items}
        onChange={(_, option) => updateChoiceTextOX(schemaKey, option)}
        allowFreeInput
      />
      <small
        style={
          disabled
            ? { color: NeutralColors.gray50, padding: '0.40em' }
            : { color: SharedColors.red20, padding: '0.40em' }
        }
      >
        {choiceTextOX.errorMessage}
      </small>
      <StyledTextField
        label={t('label.notes')}
        disabled={disabled}
        value={choiceTextOX.notes}
        onChange={e => updateNotesOX(schemaKey, e.currentTarget.value)}
        multiline
        styles={{ root: { marginTop: '1em' } }}
      />
      <CopyFromSelectionBtn
        onClick={() => copyFromSelection(schemaKey)}
        disabled={copySelectionDisabled || disabled}
      />
    </>
  )
}
