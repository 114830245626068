import {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  PropsWithChildren,
  useEffect,
} from 'react'
import { IChoiceGroupOption, IComboBoxOption } from '@fluentui/react'

import {
  dateValidator,
  positiveNumberValidator,
  textValidator,
  ValidatorFunc,
  ValidatorValue,
} from '@blaw/contracts-api-schema'
import { KeyTermValue } from '@modules/KeyTerm'
import { shortDate } from '@modules/utils'
import useDocumentSelection from '@hooks/useDocumentSelection'

type FormDataEntryType = {
  label: string
  value: KeyTermValue
  isValid: boolean
  errorMessage: string
  included: boolean
  items: IComboBoxOption[]
  notes: string
}

export type KeyFormDataEntryType = {
  [key: string]: FormDataEntryType
}

export type FormDataType = {
  isUnsavedDocument: boolean
  entries: KeyFormDataEntryType
}

interface OXContextState {
  copySelectionDisabled: boolean
  formData: FormDataType
  formValid: boolean
  setFormData: Dispatch<SetStateAction<FormDataType>>
  toggleObligation: (key: string) => void
  updateBooleanOX: (key: string, option: IChoiceGroupOption | undefined) => void
  updateTextOX: (key: string, value: string) => void
  updateDateOX: (key: string, value: Date | null | undefined) => void
  updateNumericOX: (key: string, value: string) => void
  updateChoiceTextOX: (key: string, option: IComboBoxOption | undefined) => void
  updateNotesOX: (key: string, value: string) => void
  copyFromSelection: (key: string) => void
  handleFormValidation: (formData: FormDataType) => void
}

export const OXContext = createContext({} as OXContextState)

function OXContextProvider(props: PropsWithChildren) {
  const selection = useDocumentSelection()
  const [copySelectionDisabled, setCopySelectionDisabled] = useState(true)
  const [formData, setFormData] = useState({} as FormDataType)
  const [formValid, setFormValid] = useState(false)

  useEffect(() => {
    setCopySelectionDisabled(!selection.length)
  }, [selection])

  function toggleObligation(key: string) {
    const updatedOX = { ...formData.entries[key], included: !formData.entries[key].included }
    updateFormData(key, updatedOX)
  }

  const updateBooleanOX = (key: string, option: IChoiceGroupOption | undefined) => {
    let bool
    if (option?.key === 'Yes') bool = true
    if (option?.key === 'No') bool = false
    const updatedBooleanOX = {
      ...formData.entries[key],
      value: bool,
      errorMessage: '',
      isValid: true,
    }
    updateFormData(key, updatedBooleanOX)
  }

  const updateDateOX = (key: string, value: Date | null | undefined) => {
    const { isValid, errorMessage } = handleOXValidation<Date>(value as Date, dateValidator)
    const updatedDateOX = {
      ...formData.entries[key],
      value: shortDate(value as Date),
      errorMessage,
      isValid,
    }
    updateFormData(key, updatedDateOX)
  }

  const updateNumericOX = (key: string, value: string) => {
    const { isValid, errorMessage } = handleOXValidation<number>(
      parseInt(value),
      positiveNumberValidator,
    )
    const updatedNumericOX = { ...formData.entries[key], value, errorMessage, isValid }
    updateFormData(key, updatedNumericOX)
  }

  const updateTextOX = (key: string, value: string) => {
    const { isValid, errorMessage } = handleOXValidation<string>(value, textValidator)
    const updatedTextOX = { ...formData.entries[key], value, errorMessage, isValid }
    updateFormData(key, updatedTextOX)
  }

  const updateChoiceTextOX = (key: string, option: IComboBoxOption | undefined) => {
    const isValid = !!(option && option?.key !== undefined)
    const updatedChoiceTextOX = {
      ...formData.entries[key],
      value: option?.key.toString(),
      errorMessage: '',
      isValid,
    }
    updateFormData(key, updatedChoiceTextOX)
  }

  const updateNotesOX = (key: string, value: string) => {
    const updatedOXNote = { ...formData.entries[key], notes: value }
    setFormData(previous => {
      const updatedFormData = { ...previous, [key]: updatedOXNote }
      return updatedFormData
    })
  }

  const copyFromSelection = (key: string) => {
    const updatedOX = { ...formData.entries[key], notes: selection }
    setFormData(previous => {
      const updatedFormData = { ...previous, [key]: updatedOX }
      return updatedFormData
    })
  }

  function updateFormData(key: string, updatedOX: FormDataEntryType) {
    setFormData(previous => {
      const updatedFormData = { ...previous, entries: { ...previous.entries, [key]: updatedOX } }
      handleFormValidation(updatedFormData)
      return updatedFormData
    })
  }

  function handleOXValidation<T extends ValidatorValue>(value: T, validator: ValidatorFunc<T>) {
    let isValid = false
    let errorMessage = ''

    try {
      isValid = validator(value)
    } catch (e) {
      errorMessage = (e as Error).message
    }

    return { isValid, errorMessage }
  }

  function handleFormValidation(formData: FormDataType) {
    let numSelected = 0
    const formValid = Object.entries(formData.entries).every(([_, entry]) => {
      entry.included && numSelected++
      return !entry.included || entry.isValid
    })
    if (formData.isUnsavedDocument) {
      setFormValid(formValid)
    } else {
      setFormValid(formValid && !!numSelected)
    }
  }

  const value: OXContextState = {
    copySelectionDisabled,
    formData,
    formValid,
    setFormData,
    toggleObligation,
    updateBooleanOX,
    updateDateOX,
    updateNumericOX,
    updateTextOX,
    updateChoiceTextOX,
    updateNotesOX,
    copyFromSelection,
    handleFormValidation,
  }

  return <OXContext.Provider value={value}>{props.children}</OXContext.Provider>
}

export default OXContextProvider
