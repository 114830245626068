import { FontSizes, IToggleStyles, List, SharedColors, Stack, Toggle } from '@fluentui/react'
import { useTranslation } from 'react-i18next'

import LoadingShimmer from '@components/LoadingShimmer'
import Contract from '@modules/Contract'
import ResourceListHeadings from '@components/ResourceListHeadings'
import TextWithLabel from '@components/TextWithLabel'
import ContractsCard from '@components/ContractsCard'
import StatusBadge from '@components/StatusBadge'
import { Dispatch, SetStateAction, useContext } from 'react'
import HighlightedText from '@baseComponents/HighlightedText'
import { StoreContext } from '@contexts/StoreContext'
import { UserInfo } from '@modules/GetUsers'
import ResourceAccessLabel from '@components/ResourceAccessLabel'

const toggleStyles: Partial<IToggleStyles> = {
  root: { marginBottom: '0' },
  label: { marginLeft: '0.5em' },
}

export interface ContractsListProps {
  contracts: Contract[]
  loading?: boolean
  users: UserInfo[]
  loadingUsers: boolean
  isDocCardExpanded: boolean
  setIsDocCardExpanded: Dispatch<SetStateAction<boolean>>
  isViewingDocuments: boolean
}

const ContractsList: React.FunctionComponent<ContractsListProps> = ({
  loading,
  contracts,
  users,
  loadingUsers,
  isDocCardExpanded,
  setIsDocCardExpanded,
  isViewingDocuments,
}: ContractsListProps) => {
  const { t } = useTranslation()
  const { isResourceAccessEnabled, isResourceAccessV2Enabled, access, getSession } =
    useContext(StoreContext)
  const userInfo = getSession()

  if (loading || loadingUsers) return <LoadingShimmer />

  return (
    <div style={{ marginBottom: '2em' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ResourceListHeadings
          label={
            isViewingDocuments ? t('label.contracts-search-results') : t('label.all-contracts')
          }
        />
        {isViewingDocuments && (
          <Toggle
            label={t('label.show-details')}
            inlineLabel
            styles={toggleStyles}
            defaultChecked={isDocCardExpanded}
            onChange={() => setIsDocCardExpanded(!isDocCardExpanded)}
          />
        )}
      </div>
      <List onShouldVirtualize={() => false} items={contracts} onRenderCell={renderCard} />
    </div>
  )

  function renderCard(item: Contract | undefined) {
    if (!item) return null

    const { beforeContent, content, iconName, iconColor } = populateCard(item)

    return (
      <ContractsCard
        contract={item}
        beforeContent={beforeContent}
        iconName={iconName}
        iconColor={iconColor}
      >
        {content}
      </ContractsCard>
    )
  }

  function populateCard(item: Contract) {
    const enableAccessIcon =
      (isResourceAccessEnabled || isResourceAccessV2Enabled) &&
      (access.isAdmin() || userInfo.uuid === item.owner)

    if (!isViewingDocuments)
      return {
        content: (
          <>
            <TextWithLabel
              label={t('label.ContractMetadata.last-modified')}
              content={item.dateModified}
            />
            {renderStatusAndAccess()}
          </>
        ),
        iconName: 'FabricFolder',
        iconColor: SharedColors.blue10,
      }

    return {
      beforeContent: (
        <div style={{ marginBottom: '0.7em' }}>
          <TextWithLabel label={t('label.ContractMetadata.contract')} content={item.parentName} />
          <TextWithLabel
            label={t('label.ContractMetadata.last-modified')}
            content={item.dateModified}
          />
          <TextWithLabel label="Type" content={item.type} />
          {renderStatusAndAccess()}
        </div>
      ),
      content: (
        <>
          {isDocCardExpanded && (
            <HighlightedText text={item.digests?.[0]} title={t('label.keyword-match')} />
          )}
        </>
      ),
      iconName: item.icon.iconName,
      iconColor: item.icon.iconColor,
    }

    function renderStatusAndAccess() {
      return (
        <Stack
          horizontal
          style={{
            alignItems: 'center',
            display: 'flex',
            marginTop: '0.3em',
          }}
        >
          <StatusBadge status={item.status} badgeColor={item.badgeColor}></StatusBadge>
          {enableAccessIcon && (
            <ResourceAccessLabel
              access={item.accessControls}
              users={users}
              enableV2={isResourceAccessV2Enabled}
              styles={{
                root: { fontSize: FontSizes.size12 },
                callout: { root: { marginLeft: '0.1em' } },
              }}
            />
          )}
        </Stack>
      )
    }
  }
}

export default ContractsList
