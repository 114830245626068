import { useContext } from 'react'
import { DatePicker } from '@fluentui/react'

import { OXContext } from '@contexts/OXContext'
import { OXFormProps } from '@modules/OXForm'
import { parseShort, webappShortDate } from '@modules/utils'
import StyledTextField from '@components/StyledTextField'
import CopyFromSelectionBtn from '@components/CopyFromSelectionBtn'
import { useTranslation } from '@hooks/useTranslation'

export default function DateOXForm({ schemaKey, submitting }: OXFormProps) {
  const { copySelectionDisabled, formData, updateDateOX, updateNotesOX, copyFromSelection } =
    useContext(OXContext)
  const { t } = useTranslation()
  const dateOX = formData.entries[schemaKey]
  const disabled = !dateOX.included || submitting

  return (
    <>
      <DatePicker
        isRequired
        label={dateOX.label}
        disabled={disabled}
        value={typeof dateOX.value === 'string' ? parseShort(dateOX.value) : undefined}
        onSelectDate={e => updateDateOX(schemaKey, e)}
        formatDate={webappShortDate}
        placeholder={t('placeholder.Web App Date Format')}
        allowTextInput
      />
      <StyledTextField
        label={t('label.notes')}
        disabled={disabled}
        value={dateOX.notes}
        onChange={e => updateNotesOX(schemaKey, e.currentTarget.value)}
        multiline
        styles={{ root: { marginTop: '1em' } }}
      />
      <CopyFromSelectionBtn
        onClick={() => copyFromSelection(schemaKey)}
        disabled={copySelectionDisabled || disabled}
      />
    </>
  )
}
