import { CSSProperties } from 'react'
import { useNavigate } from 'react-router'
import {
  FontSizes,
  FontWeights,
  Label,
  Pivot,
  PivotItem,
  SharedColors,
  Stack,
} from '@fluentui/react'
import { useLocation } from 'react-router-dom'

import TopNav from '@components/TopNav'
import StyledStack from '@components/StyledStack'
import { useContractTaskPaneViewed } from '@modules/analytics'
import ContractTitle from '@baseComponents/ContractTitle'
import { useTranslation } from '@hooks/useTranslation'
import UnstyledList from '@baseComponents/UnstyledList'
import CollapsibleItem from '@components/CollapsibleItem'
import BoldText from '@baseComponents/BoldText'
import { titleize } from '@modules/utils'
import ClauseIssueContent from '@components/ClauseIssueContent'
import TextBadge from '@components/TextBadge'
import Box from '@baseComponents/Box'
import LongTextWithLabel from '@components/LongTextWithLabel'
import {
  PlaybookComplianceResult,
  ClauseIssue,
  InstructionClauseIssue,
} from '@blaw/contracts-api-schema'
import { StyledDivider } from '@baseComponents/StyledDivider'
import InstructionIssueContent from '@components/InstructionIssueContent'

const pageTitle = 'Compliance'
const badgeStyle: CSSProperties = {
  fontSize: FontSizes.size12,
  backgroundColor: SharedColors.gray10,
  borderColor: SharedColors.gray10,
  whiteSpace: 'nowrap',
  display: 'block',
  marginTop: '0.5em',
}

export default function PlaybooksComplianceSummary() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const subtitle = t('page.Playbooks Compliance Summary.subtitle')
  const location = useLocation()
  const {
    playbook_compliance: {
      non_compliant_clauses: playbookIssues,
      compliant_clauses: playbookCompliant,
      not_reviewed: notReviewed,
    },
    instructions_compliance: {
      non_compliant_clauses: instructionIssues,
      compliant_clauses: instructionCompliant,
    },
  } = location.state as PlaybookComplianceResult

  useContractTaskPaneViewed({ pageTitle: `${pageTitle} ${subtitle}` })

  return (
    <>
      <TopNav title={pageTitle} prevPath={() => navigate(-1)} showAIGeneratedBadge />
      <StyledStack style={{ paddingBottom: '3em' }}>
        <ContractTitle title={subtitle} style={{ fontWeight: FontWeights.bold }} />
        {renderContent()}
      </StyledStack>
    </>
  )

  function renderContent() {
    const primaryInstructionIssues = instructionIssues.filter(c => c.important_clause)
    const primaryPlaybookIssues = playbookIssues.filter(c => c.important_clause)
    const secondaryInstructionIssues = instructionIssues.filter(c => !c.important_clause)
    const secondaryPlaybookIssues = playbookIssues.filter(c => !c.important_clause)
    const primaryIssuesLabel =
      secondaryInstructionIssues.length + secondaryPlaybookIssues.length > 0
        ? 'Primary Issues'
        : 'Issues'

    return (
      <>
        <Stack.Item style={{ margin: '0 1em 1em 0' }}>
          {t('page.Playbooks Compliance Summary.blurb')}
        </Stack.Item>
        <Stack.Item style={{ margin: '0 1em 1em 0' }}>
          {t('page.Playbooks Compliance Summary.blurb2')}
        </Stack.Item>
        <Stack.Item>
          <Pivot
            aria-label={pageTitle}
            styles={{
              root: { display: 'flex', justifyContent: 'center', marginBottom: '1.2em' },
            }}
          >
            {playbookIssues.length + instructionIssues.length > 0 && (
              <PivotItem
                itemKey={'0'}
                headerText="Issues"
                style={{ display: 'flex', gap: '0.3em', flexDirection: 'column' }}
              >
                <Stack.Item>
                  {renderIssuesList(
                    primaryIssuesLabel,
                    primaryInstructionIssues,
                    primaryPlaybookIssues,
                  )}
                </Stack.Item>
                <Stack.Item>
                  {renderIssuesList(
                    'Secondary Issues',
                    secondaryInstructionIssues,
                    secondaryPlaybookIssues,
                  )}
                </Stack.Item>
              </PivotItem>
            )}
            {playbookCompliant.length + instructionCompliant.length > 0 && (
              <PivotItem itemKey={'1'} headerText="Compliant">
                <Stack.Item>{renderCompliantList()}</Stack.Item>
              </PivotItem>
            )}
            {notReviewed.length > 0 && (
              <PivotItem itemKey={'2'} headerText="Not in Playbook">
                <Stack.Item>{renderNotReviewedList()}</Stack.Item>
              </PivotItem>
            )}
          </Pivot>
        </Stack.Item>
      </>
    )
  }

  function renderIssuesList(
    title: string,
    instructionIssues: InstructionClauseIssue[],
    playbookIssues: ClauseIssue[],
  ) {
    const issueCount = playbookIssues.length + instructionIssues.length
    if (!issueCount) return null

    return (
      <Box>
        <h3 style={{ margin: '0 0 0.3em' }}>{title}</h3>
        <UnstyledList>
          {[...instructionIssues, ...playbookIssues].map((item, idx) => {
            const isLastItem = idx === issueCount - 1
            const isPlaybookIssue = 'clause_title' in item
            const header = isPlaybookIssue
              ? issueHeader(item.clause_title, item.issue_type)
              : issueHeader(item.title, item.issue_type)
            const content = isPlaybookIssue ? (
              <ClauseIssueContent issue={item} />
            ) : (
              <InstructionIssueContent issue={item} />
            )
            return (
              <CollapsibleItem
                key={idx.toString()}
                item={{ ...item, key: idx.toString(), active: false }}
                itemHeader={() => header}
                itemContent={() => content}
                iconStyles={{ marginBottom: '2em', paddingLeft: 0 }}
                listItemStyles={isLastItem ? { borderBottom: 'none' } : undefined}
              />
            )
          })}
        </UnstyledList>
      </Box>
    )
  }

  function renderCompliantList() {
    return (
      <UnstyledList style={{ padding: '0 0.5em 0' }}>
        {[...instructionCompliant, ...playbookCompliant].map((item, idx) => {
          const isLastItem = idx === playbookCompliant.length + instructionCompliant.length - 1
          const label = 'clause_title' in item ? item.clause_title : item.title
          return (
            <li key={idx}>
              <LongTextWithLabel
                label={label}
                content={item.explanation}
                icon={'Checkmark'}
                styles={{ icon: { color: SharedColors.green20 } }}
              />
              {isLastItem || <StyledDivider />}
            </li>
          )
        })}
      </UnstyledList>
    )
  }

  function renderNotReviewedList() {
    return (
      <UnstyledList style={{ padding: '0 0.5em 0' }}>
        {notReviewed.map((clause_title, idx) => {
          const isLastItem = idx === notReviewed.length - 1
          return (
            <li key={idx}>
              <Label style={{ margin: '0.5em 0' }}>{clause_title}</Label>
              {isLastItem || <StyledDivider />}
            </li>
          )
        })}
      </UnstyledList>
    )
  }

  function issueHeader(clauseTitle: string, issueType: string) {
    const style = {
      ...badgeStyle,
      backgroundColor: SharedColors.magenta20,
    }
    return (
      <span style={{ position: 'relative' }}>
        <BoldText style={{ marginRight: '0.3em' }}>{titleize(clauseTitle)}</BoldText>
        <TextBadge style={style}>{issueType}</TextBadge>
      </span>
    )
  }
}
