import { useContext, useEffect, useRef, useState } from 'react'
import {
  ActionButton,
  Checkbox,
  FontWeights,
  ICheckboxProps,
  List,
  MessageBarType,
  PrimaryButton,
  SharedColors,
  Stack,
  TextField,
} from '@fluentui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { t } from 'i18next'

import {
  Resource,
  PlaybooksBuilderRequest,
  PlaybooksBuilderResponse,
  PlaybookBuilderStatusResponse,
  RESOURCETYPES_OBJ,
  getDefaultAccess,
  AccessControlLevel,
} from '@blaw/contracts-api-schema'
import { delay, friendlyDate } from '@modules/utils'
import { PaginationFooterProps } from '@hooks/usePagination'
import { StoreContext } from '@contexts/StoreContext'
import { TemplatesContext } from '@contexts/TemplatesContext'
import { itemClicked, useContractTaskPaneViewed } from '@modules/analytics'
import { useTranslation } from '@hooks/useTranslation'
import ApiClient from '@modules/ApiClient'
import BoldText from '@baseComponents/BoldText'
import ErrorMessage from '@components/ErrorMessage'
import KeywordSearch from '@components/KeywordSearch'
import LinkButton from '@components/LinkButton'
import LoadingShimmer from '@components/LoadingShimmer'
import ModalProgress from '@components/ModalProgress'
import NotificationBadge from '@components/NotificationBadge'
import ResourceTitle from '@components/ResourceTitle'
import StyledStack from '@components/StyledStack'
import StyledTextField from '@components/StyledTextField'
import Text from '@baseComponents/Text'
import TopNav from '@components/TopNav'
import useLoadingFadeStyle from '@hooks/useLoadingFadeStyle'
import { CreationType } from '@modules/Playbook'
import ComboboxField from '@components/ComboboxField'

export const playbookTemplatesPerPage = 5

export default function PlaybookBuilder() {
  const [creating, setCreating] = useState(false)
  const [hideProgress, setHideProgress] = useState(true)
  const [error, setError] = useState('')
  const [title, setTitle] = useState('')
  const [partyRole, setPartyRole] = useState('')
  const [contractType, setContractType] = useState('')
  const [templateId, setSelectedTemplate] = useState<string | null>(null)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const creationType = params.get('creationType') || ''
  const {
    error: templatesError,
    loading,
    Pagination,
    query,
    loadPage,
    search,
    templates,
    warn,
    getFacetOptions,
  } = useContext(TemplatesContext)
  const { storeSessionInfo, sessionInfo, metadataConfig, loadingMetadataConfig, routes } =
    useContext(StoreContext)
  const formRef = useRef<HTMLFormElement>(null)
  const isLoading = loading || loadingMetadataConfig || creating

  const pageTitle = t('page.Playbook Builder.Title')
  const subtitle =
    creationType === CreationType.MANUAL
      ? t('page.Playbook Builder.Manual Subtitle')
      : t('page.Playbook Builder.Template Subtitle')

  const formInvalid =
    isLoading ||
    (!templateId && creationType === CreationType.AI) ||
    (!contractType && creationType === CreationType.MANUAL) ||
    !formRef.current?.checkValidity()
  const loadingFade = useLoadingFadeStyle(isLoading)
  const stackStyles = { marginTop: '1em', ...loadingFade }

  type PostResourceResponse = {
    id: string
    version: string
  }

  useContractTaskPaneViewed({ pageTitle, eventDetails: [creationType] })

  useEffect(() => {
    loading && setSelectedTemplate(null)
  }, [loading])

  if (!metadataConfig) return null

  const { getLabel } = metadataConfig

  return (
    <form onSubmit={submit} ref={formRef}>
      <TopNav title={pageTitle} prevPath="#/playbooks/builder/1" showAIBadge />
      <ErrorMessage message={warn} type={MessageBarType.warning} />

      <StyledStack>
        <h3 style={{ marginTop: 0, fontWeight: FontWeights.semibold, ...loadingFade }}>
          {subtitle}
        </h3>
        <h3 style={{ marginBottom: '0.5em', ...loadingFade }}>
          {t('page.Playbook Builder.Basic Information')}
        </h3>
        <Stack.Item style={{ marginBottom: '0.5em' }}>
          <StyledTextField
            required
            label={t('page.Playbook Builder.Playbook Name')}
            placeholder={t('placeholder.Playbook Name')}
            value={title}
            disabled={isLoading}
            onChange={(_e, value) => setTitle(value ?? '')}
          />
        </Stack.Item>

        {creationType === CreationType.MANUAL && (
          <Stack.Item style={{ ...stackStyles, marginBottom: '0.5em' }}>
            <ComboboxField
              required
              label={getLabel('contract_type')}
              value={contractType}
              options={getFacetOptions('contract_type').filter(o => o.text !== 'undefined')}
              onChange={(_event, _option, _index, value) => setContractType(value ?? '')}
              disabled={isLoading}
            />
          </Stack.Item>
        )}

        <Stack.Item style={{ ...stackStyles, marginBottom: '0.5em' }}>
          <TextField
            required
            label={t('page.Playbook Builder.Party Role')}
            placeholder={t('placeholder.Party Role')}
            value={partyRole}
            disabled={isLoading}
            onChange={(_e, value) => setPartyRole(value ?? '')}
          />
        </Stack.Item>

        {creationType === CreationType.AI && (
          <Stack.Item>
            <Stack.Item style={{ ...stackStyles }}>
              <BoldText
                style={{ margin: '0 0.3em 0.3em 0', display: 'inline-block', ...loadingFade }}
              >
                {t('label.template')} <span style={{ color: SharedColors.red20 }}>*</span>
              </BoldText>
              {templateId && <NotificationBadge>1</NotificationBadge>}

              <KeywordSearch
                autoFocus
                query={query}
                loading={isLoading}
                search={search}
                placeholder={t('placeholder.templates-search')}
              />
            </Stack.Item>

            <Stack.Item style={stackStyles}>
              {isLoading && templates.length === 0 && <LoadingShimmer size={3} />}
              <Pagination Footer={p => <PaginationFooter {...p} templatesLoading={loading} />}>
                <List
                  key={templateId}
                  items={templates}
                  onRenderCell={(item, i) => (
                    <TemplateCheckbox
                      index={i}
                      item={item}
                      templateId={templateId}
                      checked={item && templateId === item.id}
                      onChange={(_e, checked) => {
                        if (item) {
                          setSelectedTemplate(checked ? item.id : null)
                          setContractType(item.metadata?.userMetadata.contract_type || '')
                        }
                      }}
                    />
                  )}
                  onShouldVirtualize={() => false}
                  style={{ ...loadingFade }}
                />
              </Pagination>
            </Stack.Item>
          </Stack.Item>
        )}

        <Stack.Item style={stackStyles}>
          <Stack horizontal style={{ justifyContent: 'end' }}>
            <PrimaryButton
              type="submit"
              title={formInvalid ? t('modal.Is Invalid') : ''}
              disabled={formInvalid}
              onClick={submit}
            >
              {t('button.Prepare Playbook')}
            </PrimaryButton>
            <ActionButton disabled={isLoading} onClick={() => navigate(-1)}>
              {t('button.Cancel')}
            </ActionButton>
          </Stack>
        </Stack.Item>
        <ModalProgress
          title={error ? 'An error occured' : 'Preparing your playbook'}
          description="Depending on complexity, this may take a few minutes..."
          error={error}
          hidden={hideProgress}
          cancelBtn={error ? 'Close' : false}
          onDismiss={() => setHideProgress(true)}
        />
      </StyledStack>
      <input type="submit" style={{ display: 'none' }} />
    </form>
  )

  async function createPlaybookWithAi(templateId: string): Promise<string> {
    const apiClient = new ApiClient(storeSessionInfo, setError)
    const payload: Partial<PlaybooksBuilderRequest> = {
      name: title,
      playbookTemplateIds: [templateId],
      contractType: contractType,
      resourceType: 'library_playbook',
      playbookPartyRole: partyRole,
      playbookStatus: 'DRAFT',
    }

    const { data } = await apiClient.post<PlaybooksBuilderResponse>(
      routes.playbooksBuilderUrl,
      payload,
    )
    let builderStatus: PlaybookBuilderStatusResponse = { isCompleted: false }
    const interval = 5000 // 5 second interval

    while (!(builderStatus.isCompleted && builderStatus.playbookInfo)) {
      const { data: status } = await apiClient.get<PlaybookBuilderStatusResponse>(
        routes.getPlaybooksBuilderStatusUrl(data.requestId),
      )
      builderStatus = status
      await delay(interval)
    }
    return builderStatus.playbookInfo.playbookId
  }

  async function createEmptyPlaybook(): Promise<string> {
    const apiClient = new ApiClient(storeSessionInfo, setError)
    const payload = {
      name: title,
      content: '{}',
      mimeType: 'application/json',
      accessControls: getDefaultAccess(AccessControlLevel.PUBLIC, sessionInfo.uuid || ''),
      metadata: {
        userMetadata: {
          contract_type: contractType,
          playbook_party_role: partyRole,
          playbook_status: 'DRAFT',
        },
        customMetadata: {},
        systemMetadata: {},
      },
      resourceType: RESOURCETYPES_OBJ.MY_PLAYBOOKS,
    }

    const { data } = await apiClient.post<PostResourceResponse>(routes.resourcesUrl, payload)
    return data.id
  }

  async function submit(e: { preventDefault: () => void }) {
    e.preventDefault()
    if (!formRef.current?.checkValidity()) return

    itemClicked({
      pageTitle: pageTitle,
      itemClicked: t('button.Prepare Playbook'),
      itemLocation: 'Taskpane',
      itemType: 'Form Submit',
      isLoggedIn: true,
    })
    setCreating(true)
    setHideProgress(false)
    setError('')

    try {
      let playbookId = ''
      if (creationType === CreationType.AI && templateId) {
        playbookId = await createPlaybookWithAi(templateId)
      } else if (creationType === CreationType.MANUAL) {
        playbookId = await createEmptyPlaybook()
      }

      loadPage(query)
      navigate(`/playbooks/${playbookId}/edit?name=${title}`)
    } catch (e) {
      setError((e as Error).message)
      setCreating(false)
    }
  }
}

type TemplateCheckboxProps = ICheckboxProps & {
  templateId: string | null
  item?: Resource
  index?: number
}

function TemplateCheckbox({ index, item, checked, onChange, templateId }: TemplateCheckboxProps) {
  return (
    <Stack
      horizontal
      style={{
        width: '100%',
        alignItems: 'center',
        marginBottom: '0.5em',
        justifyContent: 'space-between',
      }}
    >
      <Checkbox
        required={index === 0 && !templateId}
        checked={checked}
        onChange={onChange}
        onRenderLabel={() => (item && <ResourceTitle item={item} />) || null}
      />
      {item && (
        <Text variant="small">{item.updated ? friendlyDate(item.updated) : t('label.N/A')}</Text>
      )}
    </Stack>
  )
}

function PaginationFooter({
  loading,
  loadMore,
  onLastPage,
  numHitsRangeLabel,
  templatesLoading,
}: PaginationFooterProps & { templatesLoading: boolean }) {
  const isLoading = loading || templatesLoading
  return (
    <Stack horizontal style={{ justifyContent: 'space-between', opacity: isLoading ? 0.5 : 1 }}>
      <LinkButton disabled={isLoading || onLastPage} onClick={loadMore}>
        {isLoading ? 'Loading...' : 'Show more'}
      </LinkButton>
      <Text>{numHitsRangeLabel}</Text>
    </Stack>
  )
}
