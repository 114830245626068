import { useContext, useEffect, useRef, useState } from 'react'
import { useId } from '@fluentui/react-hooks'
import { ActionButton, PrimaryButton, Stack, TextField } from '@fluentui/react'

import TopNav from '@components/TopNav'
import { formSubmitted, useContractTaskPaneViewed } from '@modules/analytics'
import { createChat, initSession, pollTokensInBatches } from '@modules/LongPoll'
import HumanMessage from './HumanMessage'
import AIMessage from './AIMessage'
import StyledStack from '@components/StyledStack'
import ConfirmDialog from '@components/ConfirmDialog'
import { Chunk, Message, Token, UserMessage } from '@modules/Foundry'
import ApiClient from '@modules/ApiClient'
import { StoreContext } from '@contexts/StoreContext'

const pageTitle = 'Contract Assistant'

export default function ContractChatV2() {
  useContractTaskPaneViewed({ pageTitle })

  const { storeSessionInfo } = useContext(StoreContext)
  const apiClient = new ApiClient(storeSessionInfo)

  const inputRef = useRef<HTMLInputElement>(null)
  const topRef = useRef<HTMLDivElement>(null)
  const bottomRef = useRef<HTMLDivElement>(null)
  const chatUiId = useId('chat')

  const [hideNewChatConfirm, setHideNewChatConfirm] = useState(true)
  const [sessionId, setSessionId] = useState('')
  const [chatId, setChatId] = useState('')
  const [input, setInput] = useState('')
  const [messages, setMessages] = useState<Message[]>([])
  const [isLongHistory, setIsLongHistory] = useState(hasScroll(chatUiId))
  const [chatInFlight, setChatInFlight] = useState(false)
  const [intermediaryMessageChunks, setIntermediaryMessageChunks] = useState<Chunk[]>([])

  const focusInput = () => inputRef.current?.focus()
  const scrollToBottom = (behavior: ScrollBehavior = 'smooth') => {
    bottomRef.current?.scrollIntoView({ behavior })
  }

  const isCurrentMessageEmpty = !input.replaceAll(/\n/g, '').trim().length
  const clearChatDisabled = chatInFlight || messages.length === 0

  useEffect(() => {
    const initializeChat = async () => {
      const session_id = await initSession(apiClient)
      const chat_id = await createChat(session_id, apiClient)
      setSessionId(session_id)
      setChatId(chat_id)
    }

    initializeChat()
  }, [])

  useEffect(() => {
    setIsLongHistory(hasScroll(chatUiId))
    scrollToBottom('instant' as ScrollBehavior)
    focusInput()
  }, [messages.length])

  const handleSend = async () => {
    const trimmedInput = input.trim()
    if (trimmedInput) {
      const updatedMessages: Message[] = [
        ...messages,
        { role: 'user', content: trimmedInput } as UserMessage,
      ]
      setMessages(updatedMessages)
      setInput('')
      setChatInFlight(true)
      const allTokens: Token[] = []
      for await (const tokenBatch of pollTokensInBatches(
        sessionId,
        chatId,
        [
          {
            type: 'text',
            value: trimmedInput,
          },
        ],
        apiClient,
      )) {
        tokenBatch.forEach(token => {
          setIntermediaryMessageChunks(prevChunks => [...prevChunks, token])
          allTokens.push(token)
          scrollToBottom('instant' as ScrollBehavior)
        })
      }
      const newMessages = [
        ...updatedMessages,
        {
          role: 'assistant',
          chunks: allTokens,
          loadingSteps: [],
        } as Message,
      ]
      setMessages(newMessages)
      setChatInFlight(false)
      setIntermediaryMessageChunks([])
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <TopNav title={pageTitle} showAIGeneratedBadge />
      <StyledStack
        style={{
          padding: '0 0 0.5em 0.7em',
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'space-between',
        }}
      >
        <Stack.Item
          id={chatUiId}
          style={{
            display: 'flex',
            flexGrow: '1',
            paddingRight: '0.5em',
            flexDirection: 'column',
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 200px)',
          }}
        >
          <div ref={topRef}></div>
          <AIMessage
            chunks={[
              { value: "Responses from Bloomberg Law's AI Assistant are ", type: 'text' },
              { value: '**AI Generated.** ', type: 'text' },
              {
                value:
                  'The AI Assistant is experimental in nature, and users are strongly advised to corroborate the information obtained, exercise professional judgement, and seek out additional sources of information as deemed necessary.',
                type: 'text',
              },
            ]}
          />

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {messages.map((message, index) =>
              message.role === 'user' ? (
                <HumanMessage key={index} message={message.content} />
              ) : (
                <AIMessage key={index} chunks={message.chunks} />
              ),
            )}
            {chatInFlight && <AIMessage chunks={intermediaryMessageChunks} />}
          </div>
          <div ref={bottomRef}></div>
        </Stack.Item>

        <Stack.Item style={{ display: 'flex', flexDirection: 'column' }}>
          <Stack horizontal style={{ gap: '0.5em', marginRight: '0.6em' }}>
            <TextField
              // @ts-ignore ref works fine
              ref={inputRef}
              value={input}
              onChange={(_, value) => setInput(value ?? '')}
              onKeyDown={e => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  if (!isCurrentMessageEmpty) {
                    handleSend()
                  } else {
                    e.preventDefault()
                  }
                }
              }}
              placeholder="Ask a question..."
              multiline
              autoAdjustHeight
              styles={{ root: { flexGrow: 1 } }}
              disabled={chatInFlight}
            />
            <PrimaryButton
              disabled={chatInFlight}
              onClick={handleSend}
              iconProps={{ iconName: 'Send' }}
              styles={{ root: { flexGrow: 0.1, padding: 0, minWidth: 0, height: 'auto' } }}
            />
          </Stack>
          <Stack horizontal style={{ justifyContent: 'space-between', marginTop: '0.5em' }}>
            <ActionButton
              iconProps={{ iconName: 'Chat' }}
              disabled={clearChatDisabled}
              onClick={() => setHideNewChatConfirm(false)}
              style={{ height: 'auto' }}
            >
              Start New Chat
            </ActionButton>
            <ActionButton
              iconProps={{ iconName: 'ChevronUpEnd6' }}
              disabled={!isLongHistory}
              onClick={() => topRef.current?.scrollIntoView({ behavior: 'smooth' })}
              style={{ alignSelf: 'flex-end', height: 'auto' }}
            >
              Go to top
            </ActionButton>
          </Stack>
        </Stack.Item>
      </StyledStack>

      <ConfirmDialog
        title="Start New Chat"
        hidden={hideNewChatConfirm}
        confirm="Start New Chat"
        onConfirm={() => {
          formSubmitted({
            pageTitle,
            itemClicked: 'Start New Chat',
          })
          setHideNewChatConfirm(true)
          setMessages([])
        }}
        onDismiss={() => {
          setHideNewChatConfirm(true)
          focusInput()
        }}
        onDismissed={focusInput}
      >
        Starting a new chat will erase the currently opened chat. Please confirm you want to start a
        new chat session.
      </ConfirmDialog>
    </div>
  )

  function hasScroll(id: string) {
    const el = document.getElementById(id)
    return Boolean(el && el.scrollHeight > window.innerHeight)
  }
}
