import { Dialog, DefaultButton, Stack } from '@fluentui/react'

import { dataInject } from '@hooks/useDataInjection'
import { useTranslation } from '@hooks/useTranslation'
import { useNavigate } from 'react-router-dom'

type Props = {
  hidden: boolean
  toggleHidden: () => void
  saveNewFile: () => void
  saveNewVersion: () => void
}

export default function PdfConversionActions({
  hidden,
  toggleHidden,
  saveNewFile,
  saveNewVersion,
}: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  async function onClick(action?: () => void, removeDocumentId = true) {
    action && action()
    removeDocumentId
      ? await dataInject({ documentId: undefined, convertedFromPdf: undefined })
      : await dataInject({ convertedFromPdf: undefined })
    toggleHidden()
  }

  return (
    <Dialog
      hidden={hidden}
      dialogContentProps={{
        title: 'Unsaved Word File',
      }}
      onDismiss={() => onClick()}
      modalProps={{
        isBlocking: true,
      }}
    >
      <Stack>
        <DefaultButton onClick={() => onClick(saveNewVersion, false)}>
          {t('button.Save As New Version')}
        </DefaultButton>
        <DefaultButton onClick={() => onClick(saveNewFile)} style={{ margin: '0.5em 0' }}>
          {t('button.Save As New File')}
        </DefaultButton>
        <DefaultButton onClick={() => onClick(() => navigate('/contracts/new'))}>
          {t('button.Save As New Contract')}
        </DefaultButton>
      </Stack>
    </Dialog>
  )
}
